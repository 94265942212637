import AccountActivatedSuccess from './AccountActivatedSuccess';
import CreatePasswordPage from './CreatePasswordPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import LoginPage from './LoginPage';
import PasswordResetSuccess from './PasswordResetSuccess';
import ResetPasswordPage from './ResetPasswordPage';

export const authRoutes = [
  {
    path: '',
    element: <LoginPage />,
  },
  {
    path: 'create-password',
    element: <CreatePasswordPage />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: 'reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: 'account-activated',
    element: <AccountActivatedSuccess />,
  },
  {
    path: 'password-reset-success',
    element: <PasswordResetSuccess />,
  },
];
