import { Flex, HStack, Stack, Text } from '@chakra-ui/react';

import Paper from '@/components/ui/Paper';

import { ReactComponent as SecurityIlustration } from '../assets/security.svg';
import { ForgotPasswordForm } from '../components/ForgotPasswordForm';

const ForgotPasswordPage = () => {
  return (
    <Stack width={'full'} justify="center" align={'center'}>
      <Paper w={'60%'} maxW={'900px'} p={8} color={'black'}>
        <Stack spacing={1} mb={4} align={'center'}>
          <Text fontSize={'lg'} fontWeight={'extrabold'}>
            Zapomniałeś hasła?
          </Text>
          <Text fontSize={'xl'}>4P Management</Text>
        </Stack>
        <HStack spacing={8}>
          <Flex w={'50%'}>
            <SecurityIlustration width={'100%'} height={'250'} />
          </Flex>
          <Stack w={'50%'}>
            <ForgotPasswordForm />
          </Stack>
        </HStack>
      </Paper>
    </Stack>
  );
};

export default ForgotPasswordPage;
