import { Button, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import FormInput from '@/components/form/FormInput';
import { instance } from '@/lib';

type Form = {
  email: string;
};

const INITIAL_VALUES: Form = {
  email: '',
};

const schema = yup.object({
  email: yup.string().required('Pole wymagane').email('Podaj poprawny adres e-mail'),
});

export const ForgotPasswordForm = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<Form>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const onSubmit = async (values: Form) => {
    try {
      await instance.post('/auth/forgot-password', {
        email: values.email,
      });
      setIsEmailSent(true);
    } catch (err: any) {
      toast.error(err.data.message);
    }
  };

  if (isEmailSent) {
    return (
      <Stack spacing={4} align="center">
        <Text align="center">
          Email z instrukcją resetowania hasła został wysłany na podany adres.
        </Text>
        <Text align="center">
          Sprawdź swoją skrzynkę pocztową i postępuj zgodnie z instrukcjami.
        </Text>
        <Button onClick={() => navigate('/')} colorScheme={'purple'}>
          Powrót do strony logowania
        </Button>
      </Stack>
    );
  }

  return (
    <Stack w={'full'} as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
      <FormInput error={errors.email} placeholder="Email" {...register('email')} type={'email'} />
      <Button colorScheme={'purple'} type={'submit'} isLoading={isSubmitting}>
        Wyślij link resetujący hasło
      </Button>
      <Button onClick={() => navigate('/')}>Powrót do strony logowania</Button>
    </Stack>
  );
};
