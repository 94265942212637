import { Button, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import Paper from '@/components/ui/Paper';

import { ReactComponent as HappyIlustration } from '../assets/happy.svg';

const AccountActivatedSuccess = () => {
  const navigate = useNavigate();

  return (
    <Stack width={'full'} justify="center" align={'center'}>
      <Paper w={'60%'} maxW={'900px'} p={8} color={'black'}>
        <HStack spacing={8}>
          <Flex w={'50%'}>
            <HappyIlustration width={'100%'} height={'250'} />
          </Flex>
          <Stack w={'50%'}>
            <Stack spacing={2} mb={4} align={'center'}>
              <Text fontSize={'lg'} fontWeight={'extrabold'}>
                Dziękujemy
              </Text>
              <Text fontSize={'xl'}>Twoje konto zostało aktywowane.</Text>
              <Button onClick={() => navigate('/')} colorScheme={'purple'}>
                Przejdź do logowania
              </Button>
            </Stack>
          </Stack>
        </HStack>
      </Paper>
    </Stack>
  );
};

export default AccountActivatedSuccess;
